/* globals jQuery, wordpress_globals */
import "../sass/theme.scss";
import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
// @ts-ignore
import svgxhr from "webpack-svgstore-plugin/src/helpers/svgxhr";
// Prefer creating new modules when appropriate, rather than adding functions here
// If you need something to be globally accessible, you can assign an imported module to this namespace
// I.E import Contact from './contact';
// window.namespace.Contact = Contact;
const bootstrap = {
    init: function () {
        // console.log('Hello World!');
        // this.helpers.initSvg();
        // smooth scroll to anchor
        const internalScrollAnchors = document.querySelectorAll('a[href^="#"]');
        internalScrollAnchors.forEach((anchor) => {
            // get the target element
            anchor.addEventListener('click', (e) => {
                var _a;
                e.preventDefault();
                const target = document.querySelector((_a = anchor.getAttribute('href')) !== null && _a !== void 0 ? _a : '');
                if (target) {
                    target.scrollIntoView({ behavior: 'smooth' });
                }
            });
        });
        //Allow user to interrupt auto-scrolling
        $(document).bind('scroll mousedown wheel keyup', function (e) {
            if ((e.which || e.type === 'mousedown' || e.type === 'mousewheel') && document.scrollingElement) {
                $(document.scrollingElement).stop();
            }
        });
    },
    helpers: {
        requireAll: function (r) {
            r.keys().forEach(r);
        },
        initSvg: function () {
            svgxhr({
                filename: './wp-content/themes/class/dist/sprite.svg', //URL to fetch
            });
        }
    }
};
document.addEventListener("DOMContentLoaded", () => {
    bootstrap.init();
});
// SVG Sprite Loader
bootstrap.helpers.requireAll(require.context('../images/icons/', true, /\.svg$/));
